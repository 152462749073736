@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.objrow {
  display: flex;
}
.head_0 {
  margin-top:1rem;
  font-size: 1.2em;
  font-weight:bold;
}
.head_1 {
  margin-top:1rem;
  font-size: 1em;
  margin-left:2em;
  font-weight:bold;
}
.objrow.lvl_1 {
  display: flex;
}
.linetop {
  border-top: 1px solid #CCC;
}
.objrow > div {
  flex: 1;
}
.objrow > div:first-child {
  flex: 0.1;
}
.objrow > div > p {
  margin-top: 0.6em;
}
